<template>
    <div class="grid lg:grid-cols-3 min-h-screen bg-bgGrey">
       <div class="hidden lg:block" :style="{ backgroundImage: 'url('+ require(`../assets/images/cofeeMan.gif`) + ')', backgroundSize: 'cover', backgroundPosition: 'center' }">
           
       </div>
       <div class="lg:col-span-2">
           <div class="bg-white shadow-sm p-4 lg:p-6 flex items-center justify-center space-x-5 lg:space-x-6">
               <router-link to="/"><svgicon name="bni" class="w-16 md:w-20 h-16 md:h-72"></svgicon></router-link>
               <span class="font-bold text-sm md:text-lg">Bienvenue sur la plateforme de collecte de donnée clients de la Banque Nationale d’investissement</span>
           </div>

           <div class="">
               <router-view></router-view>
           </div>
           
           <a href="https://app.tango.us/app/workflow/fd1bfa28-22c0-4cf5-9729-3b6ff92cf1a0" target="blank" class="absolute bottom-5 md:bottom-6 right-4 md:right-5">
                <div class="rounded-full flex items-center space-x-2 p-2 bg-gery70 max-h-40">
                    <span slot="icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3561 8.5601C11.8705 8.58141 11.6116 8.77678 11.5219 8.96723C11.2867 9.46693 10.691 9.68137 10.1913 9.44618C9.69158 9.211 9.47715 8.61525 9.71233 8.11554C10.2345 7.00604 11.3595 6.60191 12.2684 6.56202C13.1427 6.52365 14.2308 6.80214 14.8327 7.5871C15.2959 8.19115 15.5562 8.95686 15.4511 9.76195C15.3445 10.5783 14.8789 11.3106 14.1233 11.8664C13.1144 12.6087 13.1162 12.9874 13.1165 13.0425C13.1165 13.0433 13.1165 13.0441 13.1165 13.0447C13.1165 13.597 12.6688 14.0447 12.1165 14.0447C11.5642 14.0447 11.1165 13.597 11.1165 13.0447C11.1165 12.088 11.6729 11.1862 12.9382 10.2554C13.3271 9.96932 13.4428 9.69557 13.4679 9.50308C13.4945 9.29933 13.4368 9.05335 13.2457 8.80415C13.2322 8.78663 13.1592 8.71368 12.9763 8.64796C12.8017 8.5852 12.5821 8.55018 12.3561 8.5601Z" fill="white"/>
                            <path d="M12 23C5.9 23 1 18.1 1 12C1 5.9 5.9 1 12 1C18.1 1 23 5.9 23 12C23 18.1 18.1 23 12 23ZM12 3C7 3 3 7 3 12C3 17 7 21 12 21C17 21 21 17 21 12C21 7 17 3 12 3Z" fill="white"/>
                        </svg>
                    </span>
                    <span class="hidden md:inline text-sm lg:text-base font-semibold text-white">Besoin d’aide ?</span>
                </div>
           </a>
       </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    components: {

    },
    computed: {
        
    },
    methods: {
        
    },
}
</script>

<style scoped>

</style>